import React, { useState, useRef, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import Web3 from "web3";
import Web3Modal from "web3modal";
import WalletConnectProvider from "@walletconnect/web3-provider";
import {aeraAbi,nodeManagerAbi,usdcAbi,distributionManagerAbi,renewalManagerAbi,nftAbi,migratorAbi,rewardManagerAbi,claimAeraAbi} from '../../../data/abi';
import $ from "jquery";
import DonutChart from '../Aera/Home/DonutChart';
import { Row, Card, Col, Button, Modal, Container } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import unbalance from '../../../images/unbalance.png';
import aeraUp from '../../../images/coinbag.png';
import 
{providerOptions,aeraContract,nodeManagerContract,usdcContract,pairAddress,distributionManagerContract,renewalManagerContract,nftContract,migratorContract,claimAeraContract} 
from "../../../config";

let provider;

const web3Modal = new Web3Modal({
  cacheProvider: true, // optional
  providerOptions, // required
  disableInjectedProvider: false, // optional. For MetaMask / Brave / Opera.
});


const BasicDatatable = () => {

  const [statusModal, setStatusModal] = useState(false);
  let [statusModalContent,setStatusModalContent] = useState("");
  const [modalCentered, setModalCentered] = useState(false);
  const [renewalModal, setRenewalModal] = useState(false);
  const [renewAllModal, setRenewAllModal] = useState(false);
  let [totalUserFractals,setTotalUserFractals] = useState(0);
  let [fractalsData,setFractalsData] = useState([]);
  let [selectedFractalTimestamp,setSelectedFractalTimestamp] = useState("");
  let [nodesToMigrate,setNodesToMigrate] = useState("");

  const [contents, setContents] = useState(fractalsData);
  const [data, setData] = useState(
    document.querySelectorAll("#job_data tbody tr")
  );
  const sort = 5;
  const activePag = useRef(0);
  const [test, settest] = useState(0);
  // Active data
  const chageData = (frist, sec) => {
    for (var i = 0; i < data.length; ++i) {
      if (i >= frist && i < sec) {
        data[i].classList.remove("d-none");
      } else {
        data[i].classList.add("d-none");
      }
    }
  };
  // use effect


  // Active pagginarion
  activePag.current === 0 && chageData(0, sort);
  // paggination
  let paggination = Array(Math.ceil(data.length / sort))
    .fill()
    .map((_, i) => i + 1);

  // Active paggination & chage data
  const onClick = (i) => {
    activePag.current = i;
    chageData(activePag.current * sort, (activePag.current + 1) * sort);
    settest(i);
  };

const getNodeData =async(node,account,creationTime)=>{
  let nodeData = "";
   await node.methods._getNodeData(account,creationTime).call((err,data)=>{
       nodeData = data.expiryDate;
       //console.log(data.expiryDate);
   });
   return nodeData;
}

const autoConnect = async() =>{

  let dollarUSLocale = Intl.NumberFormat('en-US');

  if(web3Modal.cachedProvider=="injected"||web3Modal.cachedProvider=="walletconnect"){

  provider = await web3Modal.connect();

  const web3 = new Web3(provider);
  const chainID = await web3.eth.getChainId();
  if(chainID!=1088){
	web3Modal.clearCachedProvider();
	return false;
  }

  let accounts = await web3.eth.getAccounts();
  let selectedAccount = accounts[0];
  let account = selectedAccount;
  
  const aera = new web3.eth.Contract(
	aeraAbi,
	aeraContract
)

  const node = new web3.eth.Contract(
	nodeManagerAbi,
	nodeManagerContract
  );
  
  const usdc = new web3.eth.Contract(
	usdcAbi,
	usdcContract
)

  const pair_usdc_balance = await usdc.methods.balanceOf(pairAddress).call();
let balance1 = web3.utils.fromWei(pair_usdc_balance,"mwei");

const pair_aera_balance = await aera.methods.balanceOf(pairAddress).call();
let balance2 = web3.utils.fromWei(pair_aera_balance,"Ether");

let aeraPrice = parseInt(balance1)/parseInt(balance2);

//get fractals creation time
let creationDate = [];
let reward = [];
let expiryList = [];

try{
const dates =await node.methods._getNodesCreationTime(account).call();
	
	let dates1 = dates.split("#");

   for(let a=0; a<dates1.length; a++){
	   creationDate[a] = dates1[a];
     const expiryDate = await node.methods._getNodeData(account,dates1[a]).call();
     expiryList[a] = expiryDate.expiryDate;
   }  
}catch(error2){
// console.log(error2);
}
//end get fractals creation time

//get available rewards
try {
    await node.methods._getNodesRewardAvailable(account).call(
        (err,reward1)=>{
            if(!err){
        let arewards = reward1.split("#");

        for(let k=0; k<arewards.length; k++){
          
          reward[k] = web3.utils.fromWei(arewards[k],"Ether");
          
        }
    }
    });
}catch(error3){
    //console.log(error3);
}
//end get available rewards

//start user fractals
try {
    await node.methods._getNodesNames(account).call({ from:account},(err, data) => {
        if(!err){
        let nodes = data.split("#");

        var c = $("#fractalsList");
      
        if (nodes.length > 0) {
            c.html("");
            let no;
            setTotalUserFractals(totalUserFractals=nodes.length);
            $("#userFractals").html(nodes.length+" / $"+dollarUSLocale.format(nodes.length * 10 * aeraPrice.toFixed(0)));

            //console.log(creationDate);

            let expired = "";

           for (let i = 0; i < nodes.length; i++) {

            let expiryDate = expiryList[i];

                var s = new Date(parseInt(creationDate[i])*1000);

                no = i + 1;

                var _expiryDate = new Date(parseInt(expiryDate)*1000);

                var __expiryDate = _expiryDate.getDate()+"-"+parseInt(_expiryDate.getMonth()+1)+"-"+_expiryDate.getFullYear();

                const utcDate2 = new Date();

                const currentDatetimestamp = Date.UTC(utcDate2.getUTCFullYear(), utcDate2.getUTCMonth(), utcDate2.getUTCDate(),utcDate2.getUTCHours(),utcDate2.getUTCMinutes());
                
                if(currentDatetimestamp/1000>expiryList[i]){
                  expired = "true";
                  console.log(expired);
                }else {
                  expired = "false";
                  console.log(expired);
                }

                let month = parseInt(s.getMonth())+1;
                
                const costValue = "$"+dollarUSLocale.format(10*aeraPrice.toFixed(0));

                const creationDateS = s.getDate()+"-"+month+"-"+s.getFullYear();

                const aReward = reward[i];
                
                fractalsData[i] = {no:`${i}`,name:`${nodes[i]}`, costValue: `${costValue}`, creationDate: `${creationDateS}`, availableReward: `${aReward}`,creationTimestamp:`${creationDate[i]}`,expiryDate:`${__expiryDate}`,expired:`${expired}`,expiryTimestamp:`${expiryList[i]}`};

                //setFractalsData(fractalsData);

            }
            console.log(fractalsData);
            //setFractalsData(fractalsData);
         
        } else {
            c.append("");
        }
        let x = Math.floor((Math.random() * 100) + 1);
        settest(x);
    }
    });

}catch(error4){
   // console.log(error4);
}
//end user fractals

//start user rewards balance
try {
	let rewardBalance
    await aera.methods.getRewardAmount().call({ from: account },(err, data1) => {
        if(data1==undefined){
            rewardBalance = 0;
        }else {
         rewardBalance = web3.utils.fromWei(data1, "Ether");
        }
      
        $("#rewardsBalance").html(parseFloat(rewardBalance).toFixed(2)+" $AERA / $"+dollarUSLocale.format(aeraPrice * parseFloat(rewardBalance).toFixed(2)));
    });
} catch(error1){
    //console.log(error1);
}
//end user rewards balance

	}

  }

  useEffect(()=>{
   autoConnect();
   return () => {
      
  };
  },[])

  const [fractalsMonitor,setFractalsMonitor] = useState("");
  
  useEffect(()=>{
    autoConnect();
    return () => {
      
    };
  },[fractalsMonitor]);

const cashoutAll=async()=> {

    const web3 = new Web3(provider); 

    // Get list of accounts of the connected wallet
    const accounts = await web3.eth.getAccounts(); 

    let account = accounts[0];

    const aera = new web3.eth.Contract(
      aeraAbi,
      aeraContract
    )


    await aera.methods
    .cashoutAll()
    .send({ from: accounts[0] },(err,data)=>{
      if (err) {
        notifyTopFullWidth("error",err.message);
        return false;
      }
      notifyTopFullWidth("success","Transaction initiated, please wait for transaction to complete....");
      //console.log(data);
  }).once('confirmation',function(confirmationNumber,receipt){
    //console.log(confirmationNumber);
    //console.log(receipt.blockNumber);
    if(receipt.blockNumber!=0){
      let x = Math.floor((Math.random() * 100) + 1);
      setFractalsMonitor(x);
      notifyTopFullWidth("success","Transaction completed!");
    }
});
}

  const cashoutReward = async(blocktime) => {
    //$("#transaction-modal-content").html("");

    const web3 = new Web3(provider); 

      // Get list of accounts of the connected wallet
      const accounts = await web3.eth.getAccounts(); 

      let account = accounts[0];

      const aera = new web3.eth.Contract(
        aeraAbi,
        aeraContract
      )

    await aera.methods
    .cashoutReward(blocktime)
    .send({ from: accounts[0] },(err,data)=>{
        if (err) {
          notifyTopFullWidth("error",err.message);
          return false;
        }
        notifyTopFullWidth("success","Transaction initiated, please wait for transaction to complete....");
        //console.log(data);
    }).once('confirmation',function(confirmationNumber,receipt){
      //console.log(confirmationNumber);
      //console.log(receipt.blockNumber);
      if(receipt.blockNumber!=0){
        let x = Math.floor((Math.random() * 100) + 1);
        setFractalsMonitor(x);
        notifyTopFullWidth("success","Transaction completed!");
      }
  });
  }

  
const makeid=(e)=> {
  for (var t = "", n = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789", a = n.length, o = 0; o < e; o++) t += n.charAt(Math.floor(Math.random() * a));
  return t
}

  const generateName=()=>{
    //alert("Hello");
    var e, t = (e = (e = makeid(8)).replace(/\s/g, "")).length,
    n = 1,
    a = 1;
   return e;
}

const [fractalName,setFractalName] = useState(generateName());

let [fractalsToRenew,setFractalsToRenew] = useState("");

const createNode=async ()=> {
  const web3 = new Web3(provider);
  const chainID = await web3.eth.getChainId();
  if(chainID!=1088){
	web3Modal.clearCachedProvider();
	return false;
  }

  const nodePrice = 10000000000000000000;

  let accounts = await web3.eth.getAccounts();
  let selectedAccount = accounts[0];
  let account = selectedAccount;
  
  const aera = new web3.eth.Contract(
	aeraAbi,
	aeraContract
)

  let name = fractalName;

  const userBalance = await aera.methods.balanceOf(account).call();
      if(userBalance<nodePrice){
        notifyTopFullWidth("error","❌ Insufficient Aera Balance");
      return false;
      }

  await aera.methods
      .createNodeWithTokens(name)
      .send({ from: accounts[0] },
          (err, data) => {
              if (err) {
                notifyTopFullWidth("error",err.message);
                return false;
              }
              notifyTopFullWidth("success","Transaction initiated, please wait for transaction to complete....");
              //console.log(data);
          }).once('confirmation',function(confirmationNumber,receipt){
            //console.log(confirmationNumber);
            //console.log(receipt.blockNumber);
            if(receipt.blockNumber!=0){
              let x = Math.floor((Math.random() * 100) + 1);
              setFractalsMonitor(x);
              notifyTopFullWidth("success","Transaction completed!");
            }
        });
}

const notifyTopFullWidth = (type,msg) => {
  if(type=="error"){
  toast.error(msg, {
    position: "top-center",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
}
if(type=="success"){
  toast.success(msg, {
    position: "top-center",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
}
};


const approveUSDC=async()=>{

  const nodeRenewalPrice = fractalsToRenew.length*10000000;

  const web3 = new Web3(provider);

  let accounts = await web3.eth.getAccounts();
  let selectedAccount = accounts[0];
  let account = selectedAccount;

  const usdc = new web3.eth.Contract(
      usdcAbi,
      usdcContract
  )

  await usdc.methods.approve(renewalManagerContract,nodeRenewalPrice).send({from: account},(err, data) => {
    if (err) {
      notifyTopFullWidth("error",err.message);
      return false;
    }
    notifyTopFullWidth("success","Transaction initiated, please wait for transaction to complete....");
    //console.log(data);
}).once('confirmation',function(confirmationNumber,receipt){
  //console.log(confirmationNumber);
  //console.log(receipt.blockNumber);
  if(receipt.blockNumber!=0){
    //let x = Math.floor((Math.random() * 100) + 1);
    //setFractalsMonitor(x);
    notifyTopFullWidth("success","Transaction completed!");
  }
});

}

const renewFractal=async(timestamp)=>{

  const nodeRenewalPrice = fractalsToRenew.length*10000000;

  const web3 = new Web3(provider);

  let accounts = await web3.eth.getAccounts();
  let selectedAccount = accounts[0];
  let account = selectedAccount;

  const usdc = new web3.eth.Contract(
      usdcAbi,
      usdcContract
  )

  const renewalManager = new web3.eth.Contract(
    renewalManagerAbi,
    renewalManagerContract
  )

  const userBalance = await usdc.methods.balanceOf(account).call();
  if(userBalance<nodeRenewalPrice){
    //notifyTopFullWidth("error","❌ Insufficient m.USDC Balance");
  //return false;
  }

  await renewalManager.methods.renewFractal(timestamp).send({from: account},(err, data) => {
    if (err) {
      notifyTopFullWidth("error",err.message);
      return false;
    }
    notifyTopFullWidth("success","Transaction initiated, please wait for transaction to complete....");
    //console.log(data);
}).once('confirmation',function(confirmationNumber,receipt){
  //console.log(confirmationNumber);
  //console.log(receipt.blockNumber);
  if(receipt.blockNumber!=0){
    let x = Math.floor((Math.random() * 100) + 1);
    setFractalsMonitor(x);
    setRenewAllModal(false);
    notifyTopFullWidth("success","Transaction completed!");
  }
});

}

const selectAll=async()=>{
  let timestamp = [];
  
  $("input[type=checkbox]").prop('checked',function(){

  timestamp.push($(this).val());

  });
  setFractalsToRenew(fractalsToRenew=timestamp);

  setRenewAllModal(true);
}

const renewalCountDown=(timestamp)=>{
//console.log(timestamp);
  let countDownDate = new Date(timestamp*1000).getTime();

  var x = setInterval(function() {
		 
	// Get today's date and time
	var now = new Date().getTime();
  
	// Find the distance between now and the count down date
	var distance = countDownDate - now;
  
	// Time calculations for days, hours, minutes and seconds
	var days = Math.floor(distance / (1000 * 60 * 60 * 24));
	var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
	var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
	var seconds = Math.floor((distance % (1000 * 60)) / 1000);
  
	// Display the result in the element with id="demo"
	$(".countdown"+timestamp).html(days+"d "+hours + "h " + minutes + "m " + seconds + "s ");
  
	// If the count down is finished, write some text
	if (distance < 0) {
	  clearInterval(x);
	  $(".countdown"+timestamp).html("expired");
	}
  }, 1000);

}

const migrate1=async()=>{

  provider = await web3Modal.connect();

  const web3 = new Web3(provider);
  const chainID = await web3.eth.getChainId();
  if(chainID!=1088){
	web3Modal.clearCachedProvider();
	return false;
  }

  let accounts = await web3.eth.getAccounts();
  let selectedAccount = accounts[0];
  let account = selectedAccount;

  const node = new web3.eth.Contract(
	nodeManagerAbi,
	nodeManagerContract
  );

  const migrator = new web3.eth.Contract(
    migratorAbi,
    migratorContract
    );

  let d = "";
  try {
    
    await node.methods._getNodesCreationTime(account).call({ from:account},(err, data) => {

      if(!err){
        let creationTimes = data.split("#");
        for(let a=0; a<creationTimes.length; a++){
          let t = parseInt(creationTimes[a]);
          d=creationTimes+",";
        }
        d = "["+d.slice(0,-1)+"]";
        setNodesToMigrate(nodesToMigrate=d);
      }else {
        console.log(err);
      }

    });

    setStatusModalContent(statusModalContent="Complete the transaction in your wallet...");
    
    setStatusModal(true);
 
    await migrator.methods.migrate(JSON.parse(nodesToMigrate)).send({ from:account},(err, data) => {

      if(err){ 
        setStatusModalContent(statusModalContent=err.message);
        console.log(err);
      }

    }).once('confirmation',function(confirmationNumber,receipt){

      if(receipt.blockNumber!=0){
        setStatusModal(false);
        notifyTopFullWidth("success","Transaction completed!");
      }
  });

  }catch(err){
    console.log(err);
  }


};

const claimMissedRewards=async()=>{

  provider = await web3Modal.connect();

  const web3 = new Web3(provider);
  const chainID = await web3.eth.getChainId();
  if(chainID!=1088){
	web3Modal.clearCachedProvider();
	return false;
  }

  let accounts = await web3.eth.getAccounts();
  let selectedAccount = accounts[0];
  let account = selectedAccount;

  const node = new web3.eth.Contract(
	nodeManagerAbi,
	nodeManagerContract
  );

  const claimer = new web3.eth.Contract(
    claimAeraAbi,
    claimAeraContract
    );

  let d = "";
  try {

    const utcDate2 = new Date();

    const currentDatetimestamp = Date.UTC(utcDate2.getUTCFullYear(), utcDate2.getUTCMonth(), utcDate2.getUTCDate(),utcDate2.getUTCHours(),utcDate2.getUTCMinutes());
    
    let creationTimesA = await node.methods._getNodesCreationTime(account).call();

        let creationTimes = creationTimesA.split("#");

        for(let a=0; a<creationTimes.length; a++){

          let nodeData = await node.methods._getNodeData(account,creationTimes[a]).call();

          console.log(nodeData.expiryDate);
          console.log(currentDatetimestamp/1000);

          if(currentDatetimestamp/1000>nodeData.expiryDate){

          notifyTopFullWidth("error","You have expired fractal(s), please renew before claiming!");

          return false;

          }

          let t = parseInt(creationTimes[a]);

          d=creationTimes+",";

        }
        d = "["+d.slice(0,-1)+"]";
        setNodesToMigrate(nodesToMigrate=d);

    setStatusModalContent(statusModalContent="Complete the transaction in your wallet...");
    
    setStatusModal(true);
 
    await claimer.methods.claimAera(JSON.parse(nodesToMigrate)).send({ from:account},(err, data) => {

      if(err){ 
        setStatusModalContent(statusModalContent=err.message);
        console.log(err);
      }

    }).once('confirmation',function(confirmationNumber,receipt){

      if(receipt.blockNumber!=0){
        setStatusModal(false);
        notifyTopFullWidth("success","Transaction completed!");
      }
  });

  }catch(err){
    console.log(err);
  }


};

  return (
    <>
    <div className="row">
    <div className="col-xl-3 col-sm-6">
    <div className="card" style={{backgroundColor:"#0C0C0C", borderColor:"#ff6445", borderStyle:"solid", borderWidth:"1px"}}>
      <div className="card-body d-flex align-items-center justify-content-between">
        <div className="card-data me-2">
          <h5>Total Fractal Value (FPO)</h5>
          <h6><span id="userFractals" style={{color:"#2b8599"}}></span></h6>
        </div>
        <img src={unbalance} style={{width:"100px"}} />
      </div>
    </div>
  </div>

  <div className="col-xl-3 col-sm-6">
    <div className="card" style={{backgroundColor:"#0C0C0C", borderColor:"#ff6445", borderStyle:"solid", borderWidth:"1px"}}>
      <div className="card-body d-flex align-items-center justify-content-between">
        <div className="card-data me-2">
          <h5>My Rewards</h5>
          <h6 className="fs-40 font-w600"><span id="rewardsBalance" style={{color:"#2b8599"}}></span></h6>
        </div>
        <img src={aeraUp} style={{width:"100px"}} />
      </div>
    </div>
  </div>
  </div>
  <div className="row">
    <div className="col-12" style={{padding:"10px"}}>
    <a className="btn btn-primary btn-xl" href="#"  style={{width:"", marginRight:"5px!important", marginBottom:"20px!important", background:"#ff6445",border:"#ff6445; margin-right:10px!important;"}}  onClick={() => {claimMissedRewards()}}>Claim Missed Rewards</a>
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    <a className="btn btn-primary btn-xl" href="#"  style={{width:"", marginRight:"5px!important", marginBottom:"20px!important", background:"#2b8599",border:"#2b8599"}}  onClick={() => {migrate1()}}>Migrate Fractals</a>
   <br />
    </div>
   
  </div>
    <div className="col-12">
  
      <div className="card" style={{backgroundColor:"#0C0C0C", borderColor:"#ff6445", borderStyle:"solid", borderWidth:"1px"}}>
        <div className="card-header">

          <span style={{color:"#c6271a",fontSize:"18px", fontWeight:"bold"}}>Created Fractals</span>
		  <div style={{loat:"right"}}>

		  <a className="btn btn-primary btn-xxs" href="#"  style={{width:"150px", marginRight:"5px!important", marginBottom:"10px!important", background:"#ff6445",border:"#ff6445"}}  onClick={() => {setFractalName(generateName);
  setModalCentered(true)}}> Create Fractal</a>

		  &nbsp; &nbsp;
      <a className="btn btn-primary btn-xxs" href="#"  style={{width:"150px", marginRight:"5px!important", marginBottom:"10px!important", background:"#facd1e",border:"#facd1e"}} onClick={()=>selectAll()}> Renew All</a>
      &nbsp; &nbsp;
      <a className="btn btn-primary btn-xxs" href="#" onClick={()=>cashoutAll()}  style={{width:"150px"}}>Claim Rewards</a>
      &nbsp; &nbsp;
      <a className="btn btn-primary btn-xxs" href="#"  style={{width:"200px",background:"#2b8599",border:"#2b8599"}}>Compound (Soon)</a>
		  </div>
        </div>
        <div className="card-body">

          <div className="table-responsive">
            <div id="job_data" className="dataTables_wrapper">
              <table
                className="display w-100 dataTable "
                id="example5"
                role="grid"
                aria-describedby="example5_info"
              >
                <thead>
                  <tr role="row">
          
				  <th style={{ width: "50px" }}>
                      No
                    </th>
                    <th style={{ width: "177px" }}>
                      Name
                    </th>
                    <th style={{ width: "100px" }}>
                      Value (USD)
                    </th>
                    <th  style={{ width: "128px" }}>
                      Creation Date
                    </th>
                    <th style={{ width: "46px" }}>
                      Reward
                    </th>
                    <th>Renewal Date</th>
                    <th style={{ width: "114px" }}>
                     
                    </th>
                  </tr>
                </thead>

                <tbody>
                {fractalsData.map((dataUL,i)=>(
                            <tr className="odd" role="row" key={i}>
                            
                              {dataUL.expired=="true"&&
                             
                               <input style={{visibility:"hidden", display:"none"}} type="checkbox" value={dataUL.creationTimestamp} checked="" />
                          
                              }
                            <td colSpan="">
                              {parseInt(dataUL.no)+1}
                            </td>
                  <td colSpan="">
                  {dataUL.name}
                            </td>
                  <td colSpan="">
                  {dataUL.costValue}
                            </td>
                  <td colSpan="">
                  {dataUL.creationDate}
                
                            </td>
                  <td colSpan="" >
                  {dataUL.availableReward}
                            </td>
                            {(function () {
renewalCountDown(dataUL.expiryTimestamp)
})()}
                              {dataUL.expired=="false"&&
                               <td style={{color:"#2b8599"}} >
                             {dataUL.expiryDate}
     <p class={`countdown${dataUL.expiryTimestamp}`}></p>
                               </td>
                              }
                              {dataUL.expired=="true"&&
                               <td style={{color:"#c6271a"}}>
                                 {dataUL.expiryDate}
                               {/* <div>
                              
<a className="btn btn-dark btn-xxs" href="#" onClick={() => {setSelectedFractalTimestamp(dataUL.creationTimestamp); setFractalName(dataUL.name);
setRenewalModal(true)}}  style={{width:"150px"}}>Renew Fractal</a>
  </div>*/}
  </td>
                              }
                            
                  <td colSpan="">
                  <a className="btn btn-dark btn-sm" href="#" onClick={()=>cashoutReward(dataUL.expiryDate)}  style={{width:"150px"}}>Claim</a>
                            </td>
                       </tr>
                ))}
                </tbody>
              
              </table>
             
            </div>
          </div>
        </div>
      </div>
    </div>

    <Modal className="fade" show={renewAllModal}>
                  <Modal.Header>
                    <Modal.Title>Renew Fractal</Modal.Title>
                    <Button
                      onClick={() => setRenewAllModal(false)}
                      variant=""
                      className="btn-close"
                    >
                      
                    </Button>
                  </Modal.Header>
                  <Modal.Body>
                    
                  {fractalsToRenew.length>0&&
                 <>
                  <div className="input-group mb-3  input-danger">
                 
                    You are about to renew {fractalsToRenew.length} fractals for a total of ${fractalsToRenew.length*0} m.USDC
                 
                    </div>
                    
                        Note: Click on approve m.USDC first before clicking on Renew</>
                      }

{fractalsToRenew.length<1&&
                 <>
                 <h4>No fractal to renew</h4>
                 </>
}
                      
                  </Modal.Body>
                  <Modal.Footer>
                    {fractalsToRenew.length>0&&
                    <>
                  <Button variant="primary" onClick={approveUSDC}>Approve m.USDC</Button>

                  <Button variant="primary" onClick={()=>{renewFractal(fractalsToRenew)}}>Renew Fractal</Button>
                  </>
                    }
                    <Button
                      onClick={() => setRenewAllModal(false)}
                      variant="danger light"
                    >
                      Close
                    </Button>
                    
                  </Modal.Footer>
                </Modal>

    <Modal className="fade" show={renewalModal}>
                  <Modal.Header>
                    <Modal.Title>Renew Fractal</Modal.Title>
                    <Button
                      onClick={() => setRenewalModal(false)}
                      variant=""
                      className="btn-close"
                    >
                      
                    </Button>
                  </Modal.Header>
                  <Modal.Body>
                  <div className="input-group mb-3  input-danger">
                    <span className="input-group-text">Name</span>
                    <input type="text" value={fractalName} className="form-control" />
                    </div>
                        Note: Click on approve m.USDC first before clicking on Renew
                  </Modal.Body>
                  <Modal.Footer>
                  <Button variant="primary" onClick={approveUSDC}>Approve m.USDC</Button>

                  <Button variant="primary" onClick={()=>{renewFractal(selectedFractalTimestamp)}}>Renew Fractal</Button>

                    <Button
                      onClick={() => setRenewalModal(false)}
                      variant="danger light"
                    >
                      Close
                    </Button>
                    
                  </Modal.Footer>
                </Modal>


                <Modal className="fade" show={modalCentered}>
                  <Modal.Header>
                    <Modal.Title>Create Fractal</Modal.Title>
                    <Button
                      onClick={() => setRenewalModal(false)}
                      variant=""
                      className="btn-close"
                    >
                      
                    </Button>
                  </Modal.Header>
                  <Modal.Body>
                  <div className="input-group mb-3  input-danger">
                    <span className="input-group-text">Name</span>
                    <input type="text" value={fractalName} className="form-control" />
                    </div>
                        Create 1 AERARIUM Fractal for 10 AERA Tokens.
                  </Modal.Body>
                  <Modal.Footer>
                  <Button variant="primary" onClick={createNode}>Create Fractal</Button>
                    <Button
                      onClick={() => setModalCentered(false)}
                      variant="danger light"
                    >
                      Close
                    </Button>
                    
                  </Modal.Footer>
                </Modal>

                <Modal className="fade" show={statusModal}>
                  <Modal.Header>
                    <Modal.Title>Transaction detail</Modal.Title>
                    <Button
                      onClick={() => setRenewalModal(false)}
                      variant=""
                      className="btn-close"
                    >
                      
                    </Button>
                  </Modal.Header>
                  <Modal.Body>
                   {statusModalContent}
                  </Modal.Body>
                  <Modal.Footer>
                 
                    <Button
                      onClick={() => setStatusModal(false)}
                      variant="danger light"
                    >
                      Close
                    </Button>
                    
                  </Modal.Footer>
                </Modal>

                <ToastContainer
                  position="top-center"
                  autoClose={5000}
                  hideProgressBar={false}
                  newestOnTop
                  closeOnClick
                  rtl={false}
                  pauseOnFocusLoss
                  draggable
                  pauseOnHover
                />
                
    </>
  );
};

export default BasicDatatable;
