import React, { Component } from "react";
import { Doughnut } from "react-chartjs-2";

class DonutChart extends Component {

  constructor(props){
    super(props);
    this.state = {totalFractals:this.props.value,totalCount:this.props.totalCount}
  }

  totalT() {
     return this.props.value;
  }

  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    if (this.props.value !== prevProps.value) {
      this.setState({totalFractals:this.totalT()})
    }
  }

  render() {
    const data = {
		weight: 0,
		defaultFontFamily: "Poppins",
		datasets: [
			{
				data: [this.state.totalFractals, this.props.totalCount - this.state.totalFractals],
				borderWidth: 0,
				backgroundColor: [
					this.props.backgroundColor,
					this.props.backgroundColor2,
				],
			},
		],
    };

    const options = {
      width: 90,
      cutoutPercentage: 50,
      responsive: false,
      maintainAspectRatio: true,
      tooltips: { enabled: false },
      hover: { mode: null },
    };
    return (
      <div className="donught-chart" style={{ marginTop: "-10px" }}>
        <Doughnut data={data} options={options} height={90} width={90} />
      </div>
    );
  }
}

export default DonutChart;
