import React, { useContext, useEffect, useState } from 'react';
import Web3 from "web3";
import Web3Modal from "web3modal";
import WalletConnectProvider from "@walletconnect/web3-provider";
import { aeraAbi, nodeManagerAbi, usdcAbi, distributionManagerAbi, nftAbi } from '../../../data/abi';
import $ from "jquery";
import { providerOptions, aeraContract, nodeManagerContract, usdcContract, pairAddress, distributionManagerContract, renewalManagerContract, nftContract }
	from "../../../config";

import LightGallery from 'lightgallery/react';
// import styles
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-thumbnail.css';
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgZoom from 'lightgallery/plugins/zoom';

import big1 from '../../../images/big/img1.jpg';
import big2 from '../../../images/big/img2.jpg';
import big3 from '../../../images/big/img3.jpg';
import big4 from '../../../images/big/img4.jpg';
import big5 from '../../../images/big/img5.jpg';
import big6 from '../../../images/big/img6.jpg';
import big7 from '../../../images/big/img7.jpg';
import big8 from '../../../images/big/img8.jpg';
import { useLocation } from 'react-router-dom'

//Import
import { ThemeContext } from "../../../context/ThemeContext";
import PageHead from '../Aera/PageHead';
import DonutChart from '../Aera/Home/DonutChart';

import Fractals from "./fractals.js";

import allFractals from "../../../images/allFractals.png";
import unbalance from "../../../images/unbalance.png";
import coinbag from "../../../images/coinbag.png";
import aeraUp from "../../../images/aeraUp.png";
import logo from "../../../images/logo.png";
import hermesLogo from "../../../images/maiaicon.png";

import { Configuration, OpenAIApi } from "openai";

const configuration1 = new Configuration({
	apiKey: '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',
});


const lightGallery = [
	{ large: big1, thumb: big1, },
	{ large: big2, thumb: big2, },
	{ large: big3, thumb: big3, },
	{ large: big4, thumb: big4, },
	{ large: big5, thumb: big5, },
	{ large: big6, thumb: big6, },
	{ large: big7, thumb: big7, },
	{ large: big8, thumb: big8, },
];

const openai = new OpenAIApi(configuration1);

const Home = ({ pageName }) => {
	const onInit = () => {
		//console.log('lightGallery has been initialized');
	};

	const {
		changeBackground, changeNavigationHader,
		changePrimaryColor, chnageSidebarColor
	}
		= useContext(ThemeContext);

	let provider;

	const web3Modal = new Web3Modal({
		cacheProvider: true, // optional
		providerOptions, // required
		disableInjectedProvider: false, // optional. For MetaMask / Brave / Opera.
	});

	//console.log(web3Modal);

	let [totalFractals] = useState("");
	let [totalA, setTotalA] = useState(0);
	let [totalFractalsValue, setTotalFractalsValue] = useState(0);
	let [totalUserFractals, setTotalUserFractals] = useState(0);
	let [totalUserRewards, setTotalUserRewards] = useState(0);
	let [totalMinted, setTotalMinted] = useState("");
	let [totalMintedPercent, setTotalMintedPercent] = useState("");
	let [mStyle, setmStyle] = useState("");
	let [generating, setGenerating] = useState(false);
	let [answer, setAnswer] = useState();
	let [question, setQuestion] = useState("mickey mouse dressed in suit");
	let [images, setImages] = useState([]);



	const autoConnect = async () => {

	}

	const generate = async () => {

		setGenerating(true);

		try {

			const response = await openai.createImage({
				prompt: JSON.stringify(`${question}`),
				n: 4,
				size: "1024x1024",
			});

			console.log(response.data.data);

			//let image_url = response.data.data[0].url;

			//setAnswer(image_url)

			setImages(response.data.data);

			setGenerating(false);

		} catch (e) {
			console.log(e)
			setGenerating(false);
		}
	}

	//autoConnect();

	return (
		<>
			<PageHead activePage="Paper AI" pageName="Paper AI" />
			<p>Generate high quality images using our text to image powerful artificial intelligence system. Type a detailed description of the image to generate and click on generate</p>
			<hr style={{ size: "0px", height: "1px", color: "#c0c0c0" }} />
			{/*<div className="row" >
				<div className="col-xl-12 col-xxl-12">
					<center><h1>Loading data.....</h1></center>
				</div>
	</div>*/}

			<div className="row">

				<div className="col-xl-12 col-sm-12">

					<div className="basic-form ">
						<form action="#" onSubmit={(e) => { e.preventDefault(); generate() }}>

							<div className="input-group mb-3 border-0" style={{
								webkitBoxShadow: "0px 0px 10px 0px rgba(186,186,186,1)",
								mozBoxShadow: "0px 0px 10px 0px rgba(186,186,186,1)", boxShadow: "0px 0px 10px 0px rgba(186,186,186,1)", borderRadius: "15px"
							}}>

								<input style={{ color: "#000", border: 0 }} type="text" className="form-control bg-white" onChange={(element) => { setQuestion(element.target.value); console.log(element.target.value) }} value={question} />

								{generating &&
									<button className="btn btn-success" type="button" disabled="disabled">
										Generating...
									</button>
								}
								{!generating &&
									<button className="btn btn-success" type="button" onClick={generate}>
										Generate
									</button>
								}

							</div>

						</form>
					</div>
				</div>

			</div>

			<div className="row">
				<div className="col-lg-12">
					<div className="card bg-white" style={{
						webkitBoxShadow: "0px 0px 10px 0px rgba(186,186,186,1)",
						mozBoxShadow: "0px 0px 10px 0px rgba(186,186,186,1)", boxShadow: "0px 0px 10px 0px rgba(186,186,186,1)", borderRadius: "15px"
					}} >
						<div className="card-header" style={{borderBottom:1,borderBottomColor:"#e7e7e7", borderBottomStyle:"solid"}}>
							<h4 className="card-title" style={{color:"#000!important;"}}>Result</h4>
						</div>

						<div className="card-body pb-1">
							<LightGallery
								onInit={onInit}
								speed={500}
								plugins={[lgThumbnail, lgZoom]}
								elementClassNames="row"
							>
								{images.map((item, index) => (
									<div data-src={item.url} className="col-lg-3 col-md-6 mb-4" key={index}>
										<img src={item.url} style={{ width: "100%" }} alt="gallery" className='cursor-pointer' />
									</div>
								))}
							</LightGallery>

						</div>
					</div>
					{/* <!-- /# card --> */}
				</div>
			</div>

		</>
	)

}
export default Home;