import React, { useEffect,useState } from "react";
import Web3 from "web3";
import Web3Modal from "web3modal";
import {aeraAbi,nodeManagerAbi,usdcAbi,distributionManagerAbi} from '../../../data/abi';

function MyData() {

    let nodeManagerContract = "0x169efdbB2194Ca00Ce159083954B43C8d6A8eC79";

      let [fractalsData,setFractalsData] = useState([]);

      let [greeting,setGreeting] = useState("Good morning");

      const loadData = async()=>{

        const web3Modal = new Web3Modal();

        let provider = await web3Modal.connect();
    
        const  web3 = new Web3(provider);
    
        let account = await web3.eth.getAccounts();

        account = account[0];
    
        console.log(account[0]);
    
        const node = new web3.eth.Contract(
            nodeManagerAbi,
            nodeManagerContract
          );

      try {
        await node.methods._getNodesNames(account).call({ from:account},(err, data) => {
            if(!err){
            let nodes = data.split("#");
    
            //var c = $("#fractalsList");
          
            if (nodes.length > 0) {
                //c.html("");
                let no;
                for (let i = 0; i < nodes.length; i++) {
                    //var s = new Date(parseInt(creationDate[i])*1000);
    
                    no = i + 1;
    
                    //let month = parseInt(s.getMonth())+1;
                    
                    //const costValue = "$"+dollarUSLocale.format(10*aeraPrice.toFixed(0));
    
                    //const creationDateS = s.getDate()+"-"+month+"-"+s.getFullYear();
    
                    //const aReward = reward[i];
                    
                    fractalsData[i] = {no:`${i}`,name:`${nodes[i]}`};
    
                    setFractalsData(fractalsData);
    
                }
                console.log(fractalsData);
                setFractalsData(fractalsData);
            } else {
               
            }
            setGreeting("Good afternoon");
        }
        });
    
    }catch(error4){
       // console.log(error4);
    }
}

useEffect(()=>{
  
    loadData();

     console.log(fractalsData);
},[]);

    return(
     <div>
         <h1>Hello</h1>
       <ul>
           {fractalsData.map((dataA)=>
           <li>{dataA.name}</li>
           )
}
        </ul>    
     </div>
    );
}

export default MyData;