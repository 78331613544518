import React, { Component, useContext, useEffect, useState } from "react";
import Web3 from "web3";
import Web3Modal from "web3modal";
import WalletConnectProvider from "@walletconnect/web3-provider";
import { nodeManagerAbi } from '../data/abi';
import ReactDOM from 'react-dom';

/// React router dom
import { Switch, Route } from "react-router-dom";



/// Layout
import Nav from "./layouts/nav";
import Footer from "./layouts/Footer";
/// Dashboard
import Home from "./components/Dashboard/Home";

import { ThemeContext } from "../context/ThemeContext";

import Bridge from "./components/Dashboard/bridge";
import Swap from "./components/Dashboard/swap";
import MyData from "./components/Dashboard/data";
import BgBg from "../images/aeraBg.jpg";
import { providerOptions, aeraContract, nodeManagerContract, usdcContract, pairAddress, distributionManagerContract, renewalManagerContract }
  from "../config";

let provider;

let connectionStatus = "false";

const web3Modal = new Web3Modal({
  cacheProvider: true, // optional
  providerOptions, // required
  disableInjectedProvider: false, // optional. For MetaMask / Brave / Opera.
});


function Markup() {

  const { menuToggle } = useContext(ThemeContext);
  const routes = [
    /// Dashboard
    { url: "", component: Home },
    { url: "dashboard", component: Home },
  ];
  let path = window.location.pathname;
  path = path.split("/");
  path = path[path.length - 1];

  let pagePath = path.split("-").includes("page");

  let [connected, setConnected] = useState("false");

  const connect = async () => {

    const web3Modal = new Web3Modal({
      cacheProvider: true,
      disableInjectedProvider: false,
      providerOptions
    });

    const provider = await web3Modal.connect();

    //console.log(provider);

    const web3 = new Web3(provider);

    const chainId = await web3.eth.getChainId();
    //console.log(provider);
    checkChain(chainId);
    // Subscribe to accounts change
    subscribe(provider);
    setConnected(connected = "true");

  }

  const disconnect = async () => {
    const web3Modal = new Web3Modal;
    web3Modal.clearCachedProvider();
    setConnected(connected = "false");

  }

  useEffect(() => {
    const web3Modal = new Web3Modal({
      cacheProvider: true,
      disableInjectedProvider: false,
      providerOptions
    });
    console.log(web3Modal);
    if (web3Modal.cachedProvider == "injected" || web3Modal.cachedProvider == "walletconnect") {
      connect();
    }
    return () => {

    };
  }, []);

  const subscribe = (provider) => {
    provider.on("accountsChanged", (accounts) => {
      setConnected(connected = "false");
      //console.log(accounts);
      setConnected(connected = "true");
    });

    // Subscribe to chainId change
    provider.on("chainChanged", (chainId) => {
      setConnected(connected = "false");
      checkChain(chainId);
      connect();
    });
  }

  const checkChain = async (chainID) => {

    let networkData;

    networkData = [

      {
        chainId: "0x38",

        chainName: "Binance Smart Chain",

        rpcUrls: ["https://bsc-dataseed1.binance.org/"],

        nativeCurrency: {

          name: "BNB",

          symbol: "BNB",

          decimals: 18,

        },

        blockExplorerUrls: ["https://bscscan.com"],

      },

    ];
    console.log(chainID);
    //console.log(window.ethereum.isMetaMask)
    /* if(chainID!=1088&&window.ethereum.isMetaMask==false){
       alert("wrong chain");
       setConnected(connected="false");
       web3Modal.clearCachedProvider();
       return false;
     }else */
    if (chainID != 56) {

      const result = await window.ethereum.request({

        method: "wallet_addEthereumChain",

        params: networkData,

      });
      setConnected(connected = "false");
      web3Modal.clearCachedProvider();
      return false;
    }
  }

  if (connected == "false") {
    return (
      <>
        <div
          id={`${!pagePath ? "main-wrapper" : ""}`}
          className={`${!pagePath ? "show" : "mh100vh"}  ${menuToggle ? "menu-toggle" : ""
            }`}
        >
          {!pagePath && <Nav connected={connected} buttonAction={connect}
          />}

          <div className={`${!pagePath ? "content-body" : ""}`}>
            <div
              className={`${!pagePath ? "container-fluid" : ""}`}
              style={{ minHeight: window.screen.height - 60 }}
            >
              <center><h1>Connect your wallet to get started...</h1></center>

            </div>
          </div>
          {!pagePath && <Footer />}
        </div>
      </>
    );
  } else {
    return (
      <>
        <div
          id={`${!pagePath ? "main-wrapper" : ""}`}
          className={`${!pagePath ? "show" : "mh100vh"}  ${menuToggle ? "menu-toggle" : ""
            }`}
        >
          {!pagePath && <Nav connected={connected} buttonAction={disconnect} />}

          <div className={`${!pagePath ? "content-body" : ""}`} style={{ backgroundColor: '#EDF2F6', backgroundSize: "100% 100%" }}>
            <div
              className={`${!pagePath ? "container-fluid" : ""}`}
              style={{ minHeight: window.screen.height - 60, paddingTop:0 }}
            >
              {path == "" &&
                <Home />
              }
              {path == "swap" &&
                <Swap pageName={path} />
              }
              {path == "bridge" &&
                <Bridge pageName={path} />
              }

            </div>
          </div>
          {!pagePath && <Footer />}
        </div>
      </>

    );
  }
};

export default Markup;
