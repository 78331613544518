import { lazy, Suspense, useEffect, useContext } from 'react';

/// Components
import Index from "./jsx";
import { ThemeContext } from "./context/ThemeContext";
import { connect, useDispatch } from 'react-redux';
import {  Route, Switch, withRouter } from 'react-router-dom';
// action
import { checkAutoLogin } from './services/AuthService';
import { isAuthenticated } from './store/selectors/AuthSelectors';
/// Style
import "./vendor/bootstrap-select/dist/css/bootstrap-select.min.css";
import "./css/style.css";

function App () {
    const { 
		menuToggle,changeBackground,changeNavigationHader,
		changePrimaryColor,chnageSidebarColor 
	}
	= useContext(ThemeContext);
	
	useEffect(() => {
		changeBackground({ value: "light", label: "Dark" });
		changeNavigationHader("color_5");
		chnageSidebarColor("color_5");
		changePrimaryColor("color_5");
	}, []);

    const dispatch = useDispatch();
    /*useEffect(() => {
        checkAutoLogin(dispatch, props.history);
    }, [dispatch, props.history]);*/
    
		return (
			<>
                <Suspense fallback={
                    <div id="preloader">
                        <div className="sk-three-bounce">
                            <div className="sk-child sk-bounce1"></div>
                            <div className="sk-child sk-bounce2"></div>
                            <div className="sk-child sk-bounce3"></div>
                        </div>
                    </div>  
                   }
                >
                    <Index />
                </Suspense>
            </>
        );
	
	
};

const mapStateToProps = (state) => {
    return {
        isAuthenticated: isAuthenticated(state),
    };
};

export default withRouter(connect(mapStateToProps)(App)); 

