import React,{useContext, useEffect,useState} from 'react';
import { useLocation } from 'react-router-dom'

//Import
import { ThemeContext } from "../../../context/ThemeContext";
import PageHead from '../Aera/PageHead';

const Bridge = () => {
	const { 
		changeBackground,changeNavigationHader,
		changePrimaryColor,chnageSidebarColor 
	}
	= useContext(ThemeContext);

	


	return(
		<>
			<PageHead activePage="Bridge"  pageName="Bridge" />
	
			<div className="row">
				<div className="col-xl-12 col-xxl-12">
					<h1>Coming soon...</h1>
				</div>
			</div>
			
		</>
	)

}
export default Bridge;