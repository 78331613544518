import React from 'react';
import {Link} from 'react-router-dom';

const PageHead = ({activePage, pageName}) =>{
	return(
		<div className="mb-sm-4 d-flex flex-wrap align-items-center text-head">
			<h2 className="mb-3 me-auto">{activePage}</h2>
			<div>
			</div>
		</div>
	)
}
export default PageHead;