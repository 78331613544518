import WalletConnectProvider from "@walletconnect/web3-provider";

export const providerOptions = {
	walletconnect: {
		package: WalletConnectProvider,
		bridge: "https://f.bridge.walletconnect.org",

		options: {
			rpc: {56: "https://bsc-dataseed1.binance.org/"}
		}
	}
};

export const aeraContract = "0xFE540D6dbAD8C68928778AaF2Be828efA4b44Fa2";
export const nodeManagerContract = "0x169efdbB2194Ca00Ce159083954B43C8d6A8eC79";
export const usdcContract = "0xEA32A96608495e54156Ae48931A7c20f0dcc1a21";
export const pairAddress = "0x86905A21757A85B476b24512A6b9E8378DAf591A";
export const distributionManagerContract = "0x17ed671F02896bbF7A84E188a769d6A73B7F6713";
export const renewalManagerContract = "0x61a9c14715118a666d5268d3c43a880F150058cF";
export const nftContract = "0x7313FC2eDC0B209b1FC397C4584fd0FC4236C4d2";
export const rewardManagerContract = "0xaD77Eb59c31050e1Db0DBE020c8886B1C1e9daE2";
export const migratorContract = "0xDFA84C1760800d1ec5e66c160EE18aB3c4DB7666";
export const claimAeraContract = "0xE2f837956ADacff8E2693a80bA2615Fb4060607F";
